*{
  box-sizing: border-box;
}
body{
  overflow-x: hidden;
}
.logo-container{
  min-width: 120px;
}
header{
  position: sticky;
  top: 0px;
  z-index: 10;
}
.carousel-inner{
  position: relative;
}
.carousel-caption{
  position: absolute;
  background-color: rgba(65, 65, 65, 0.4);
  width: 40%;
  height: fit-content;
  top: 50%!important;
  left: 50%!important;
  transform: translate(-50%, -50%)!important;
}
.top-section:hover{
  background: linear-gradient(0deg, rgb(235, 217, 184) 0%, rgba(234,248,254,1) 100%);
  cursor: pointer;
}
.missionicons{
  background-image: url('../public/images/patternbg.jpg');
  background-position: center;
  background-color: #FFF;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgb(173, 230, 209);
 
}
.bgcolorful{
  background-image: url('../public/images/colorfulbg.jpg');
  background-size: cover;
  background-position: bottom;
}
.login-container{
  background-image: url('../public/images/bgep.jpg');
  border-radius: 20px;
  padding: 10%;
  border: 4px solid green;
  box-shadow: 0px 5px 40px rgb(0, 90, 65);
}
.missionparag{
  background-color: #FFF;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgb(230, 214, 173);
}
.missionparag:hover{
  transform: scale(105%);
}
.forecastitem{
 width: 300px; 
 margin-bottom: 3rem;
 border-radius: 15px;
 box-shadow: 1px 1px 3px lightblue;
 overflow: hidden;
}
.dashlabel{
  cursor: pointer;
  width: 500px;
  color: rgb(84, 84, 84);
  transform: rotate(-90deg);
  transform-origin: top left;
  position: absolute;
  left: 15px;
  top: 65%;
}
.dashlabel:hover{
  color:rgb(239, 239, 239);
  font-weight: 600;

}
.sidepan{
  width: 60px;
  background-color: rgb(188, 188, 188);
  box-shadow: 2px 2px 2px grey;
  height: 100vh;
  border-bottom-right-radius: 60px ;
  position:sticky;
  top: 0px;
}
.forecast-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.currentW{
  background-color: rgb(255, 242, 227);
  border: 2px solid rgb(255, 200, 97);
}
.tablew{
  min-width: 800px;
  font-size: 9pt;
}
.tablew td:nth-child(even), .tablew th:nth-child(even) {
  background-color: rgba(231, 175, 255, 0.4);
}
.tablew td:nth-child(odd), .tablew th:nth-child(odd) {
  background-color: rgba(215, 255, 255, 0.4);
}
tr:nth-child(even) {
  background-color: #eab3fd66;
}
thead tr {
  background-color: #eab3fd66;
}
.calwidth{
  min-width: 800px;
  /* overflow-x: scroll; */
}
.calwidth2{
  
  overflow-x: auto;
}
.rbc-btn-group:first-child{
  display: none!important; 
}
.rbc-btn-group button:nth-child(2), .rbc-btn-group button:nth-child(3){
  display: none!important; 
}
th.rbc-header:nth-child(2){
  display: none!important; 
}
.rbc-agenda-time-cell{
  display: none!important; 
}

.rbc-toolbar-label{
  text-align: center;
  width: 100%;
  font-size: 2rem;
  font-weight: 800;
}

.espacetitle{
  background-image: url('../public/images/bgep.jpg');
  background-position: center;
  background-color: rgb(229, 245, 235);
  
}
.color-swatch{
  width:135px;
  height:19x;
  padding: 5px;
  border-radius: 10px;
  border:1px solid rgb(205, 205, 205);
  background-color: rgb(236, 236, 236);
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.color-swatch li{
  width: 15px;
  height: 15px;
}
.color-swatch li:hover{
  cursor: pointer;
}
.miaform{
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: rgb(240, 255, 250)!important;

}
.alink{
  text-decoration: none;
}
.eduCard{
  flex-wrap: wrap;
}

.photo-gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
}

.photo-gallery-container img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.photo-gallery-container img:hover {
  cursor: pointer;
}
.foodtable td, .foodtable th{
  max-width:150px;
}



.mia2anim {
  padding-right: 50px;
  padding-left: 50px;
  box-shadow: 0px 5px 10px rgb(97, 144, 210);
}

.mia2anim:hover{
  cursor: pointer;
}
.ttem{
  min-height: 900px;
}
.addagenda {
  margin-bottom: 1em;
  border: 1px solid #888;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
  background-color: rgb(160, 211, 178);
  border-radius: 20px;
}
.agendaItem{
  width: 70%;
  background-color: rgb(255, 242, 206);
  border-radius: 20px;

}
.agendatitle{
  font-size: 18pt;
  font-weight: 600;
}
.themeback{
  background-image: url('../public/images/logo512.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}
@media (max-width: 450px) {
  .hidden-link {
    display: none;
  }
}
