.eventpreview{
    position: relative;
    height: fit-content;
    min-height: 300px;
    
}
.event-content{
    position: relative;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    

}
.christmas-1::before,.christmas-2::before,.christmas-3::before,.halloween-1::before,.canada-1::before,.canada-2::before,.educatif::before,.fetemeres::before,.feteperes::before,.quebec-1::before,.grace-1::before,.labourday::before,.meeting::before,.mothersday::before,.pappillons::before,.pirates::before,.rencontre::before,.thanksgiving::before,.cirque::before,.nobackground::before,.miatialogo::before{
    content: "";
    background-color: rgb(52, 52, 52);
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.8;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 30px;
    padding: 2rem;
    min-height: 800px;
    
}
.christmas-1::before{
    background-image: url('../public/images/eventbackgrounds/christmas1.jpg');
}
.christmas-2::before{
    background-image: url('../public/images/eventbackgrounds/christmas2.jpg');
}
.christmas-3::before{
    background-image: url('../public/images/eventbackgrounds/christmas3.jpg');
}
.halloween-1::before{
    background-image: url('../public/images/eventbackgrounds/halloween1.jpg');
}
.canada-1::before{
    background-image: url('../public/images/eventbackgrounds/canadaday.jpeg');
}
.canada-2::before{
    background-image: url('../public/images/eventbackgrounds/canadaday2.jpg');
}
.educatif::before{
    background-image: url('../public/images/eventbackgrounds/educatif.jpeg');
}
.fetemeres::before{
    background-image: url('../public/images/eventbackgrounds/fetemeres.jpeg');
}
.feteperes::before{
    background-image: url('../public/images/eventbackgrounds/feteperes.jpeg');
}
.quebec-1::before{
    background-image: url('../public/images/eventbackgrounds/fetequecbec.jpeg');
}
.grace-1::before{
    background-image: url('../public/images/eventbackgrounds/grace.jpeg');
}
.labourday::before{
    background-image: url('../public/images/eventbackgrounds/labourday.jpeg');
}
.meeting::before{
    background-image: url('../public/images/eventbackgrounds/meeting.jpeg');
}
.mothersday::before{
    background-image: url('../public/images/eventbackgrounds/mothersday.jpeg');
}
.pappillons::before{
    background-image: url('../public/images/eventbackgrounds/pappillons.jpeg');
}
.pirates::before{
    background-image: url('../public/images/eventbackgrounds/pirates.jpeg');
}
.rencontre::before{
    background-image: url('../public/images/eventbackgrounds/rencontre.jpeg');
}
.thanksgiving::before{
    background-image: url('../public/images/eventbackgrounds/thanksgiving.jpeg');
}
.cirque::before{
    background-image: url('../public/images/eventbackgrounds/cirque.jpg');
}
.miatialogo::before{
    background-image: url('../public/images/eventbackgrounds/logo512.png');
}